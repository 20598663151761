/////////////////////////////////////////////
//Dependencies
import { React, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Carousel from "nuka-carousel";

/////////////////////////////////////////////
//Imported Components
import PartnersAdd from "./PartnersAdd";

/////////////////////////////////////////////
//CSS
import "./Partners.css";

/////////////////////////////////////////////
//Function

/**
 * Shuffle an array in place.
 * 
 * @param {Array} array - The array to shuffle.
 * @returns {Array}     - The shuffled array.
 */
function shuffle(array) {
  let currentIndex = array.length, temporaryValue, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

/////////////////////////////////////////////
//Component

/**
 * Partners component
 * 
 * @returns {JSX.Element} - The rendered Partners component.
 */
export default function Partners() {
  const { t } = useTranslation();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  /**
 * Get the parameters for the carousel.
 * 
 * @returns {Object} - The carousel parameters.
 */
  const getParams = () => {
    return {
      wrapAround: true,
      slidesToShow: isMobile ? 3 : 7, // mobile x 2 : desktop
      slidesToScroll: 3,
      autoplay: true,
      autoplayInterval: 2200,
      enableKeyboardControls: true,
      adaptativeHeight: true,
      adaptiveHeightAnimation: true,
      cellSpacing: 10,
      renderCenterLeftControls: () => null,
      renderCenterRightControls: () => null,
      renderBottomCenterControls: () => null,
    };
  };

  const [brands, setBrands] = useState([
    "Airliquide",
    "Thales",
    "Ionbond",
    "Mecachrome",
    "EPFL",
    "Safran",
    "Schlumberger",
    "ArcelorMittal",
    "Sodern",
    "YaleUniversity",
    "UniversityTsinghua",
    "UniversityWaterloo",
    "UniversityInnsbruck",
    "CollegeDeFrance",
    "WIS",
    "TUOC",
    "KIT",
    "Quantware",
    "AFDD",
    "WMI",
    "Riken",
    "Julich",
    "ETHZurich",
    "TUOQA",
    "CEA",
    "Rigetti",
    "NTTResearch",
    "QCI",
    "ISTA",
    "PrincetonUniversity",
    "UniBasel",
    "PTA",
    "SKKU",
    "UniversityMaryland",
    "Stanford",
    "ENS",
    "UCAS",
    "ESPCI",
    "UCB",
    "NTIS",
    "NEEL",
    "CNRS",
    "UCSB",
    "UniversityOpenhagen",
    "JWNC",
    "Tudelft",
    "UniversityOxford",
    "NationalTaiwanUniversity",
    "AaltoUniversity",
    "SLAC",
    "IQM",
    "AIST",
    "UniversityPittsburgh",
    "RHUOL",
    "Qilimanjaro",
    "LLNL",
    "NUS",
    "UDS",
    "Iquist",
    "Alice&Bob",
    "Chalmers",
    "RochesterUniversity",
    "SilentWaves"
  ]);

  useEffect(() => {
    setBrands(prevBrands => shuffle([...prevBrands]));
  }, []);

  const half = Math.ceil(brands.length / 2);
  const brandsCopy = [...brands];
  const firstHalf = brandsCopy.splice(0, half);
  const secondHalf = brandsCopy.splice(-half);

  return (
    <div className="part-container">
      <div className="part-title">
        {t("partners.title")}
      </div>
      <div className="part-carousel-container">
        {isMobile ? (
          <Carousel {...getParams()}>
            {firstHalf.map((brand, index) => (
              <div className="part-carousel-mobile" key={index}>
                <PartnersAdd brand={brand} />
                {secondHalf[index] && <PartnersAdd brand={secondHalf[index]} />}
              </div>
            ))}
          </Carousel>
        ) : (
          <Carousel {...getParams()}>
            {brands.map((brand, index) => (
              <PartnersAdd key={index} brand={brand} />
            ))}
          </Carousel>
        )}
      </div>
    </div>


  );
};
